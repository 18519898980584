module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"sizeByPixelDensity":true,"showCaptions":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-flexsearch/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"type":"MarkdownRemark","fields":[{"name":"title","indexed":true,"resolver":"frontmatter.title","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":true},{"name":"description","indexed":true,"resolver":"frontmatter.description","attributes":{"encode":"balance","tokenize":"strict","threshold":6,"depth":3},"store":false},{"name":"url","indexed":false,"resolver":"fields.slug","store":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
